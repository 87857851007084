<template>
  <div v-if="item">
    <v-avatar size="24" class="mr-2">
      <img contain height="24" :src="`https://cdn.loglive.io/flags/4x3/${params.data.countryCode.toLowerCase()}.svg`">
      </img>
    </v-avatar>
    {{ item }}

  </div>
</template>

<script>
export default {
  data: () => ({
    group: null,
    item: null,
  }),
  mounted() {
    this.item = this.params.value;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
};
</script>

<style></style>