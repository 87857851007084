<template>
  <div>
    <v-btn icon @click="editAddress(item)">
      <v-icon small>edit</v-icon>
    </v-btn>
    <v-btn icon color="danger" @click="removeAddress(item)">
      <v-icon small>delete</v-icon>
    </v-btn>
    <v-dialog v-model="addressModal" persistent width="800" :fullscreen="$vuetify.breakpoint.xs">
      <AddressModalVue @refresh="params.refresh" :item="item" @addKey="addressKeyPlus()" @close="closeAddressModal()"
        @save="saveAddress" transition="scale-transition" :dialogVisible="addressModal" />
    </v-dialog>
  </div>
</template>

<script>
import AddressModalVue from '../../SystemComponents/AddressModal.vue';
export default {
  components: {
    AddressModalVue
  },
  data: () => ({
    addressModal: false,
    item: {},
    addressItem: {}
  }),
  mounted() {
    this.item = this.params.data;
  },
  methods: {
    closeAddressModal() {
      this.addressModal = false;
      this.addressItem = {};
    },
    removeAddress(item) {
      this.$confirm(
        `Are you sure you want to remove this address?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateOrganisationAddress({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.params.refresh;
        })
    },
    editAddress(item) {
      this.addressItem = item;
      if (!this.addressItem.country) {
        this.addressItem.country = this.item.name;
        this.addressItem.countryCode = this.item.countryCode;
      }
      this.addressItem.modalType = "Edit";
      if (this.addressItem.position) {
        this.center = {
          lat: this.addressItem.position.coordinates[1],
          lng: this.addressItem.position.coordinates[0],
        };
      }
      this.addressModal = true;
    },
  },
};
</script>