<template>
    <div>
        <div v-if="loading">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-card style=" background: var(--v-greyRaised-base) !important;">
            <v-toolbar flat>
                <v-toolbar-title>{{ item.modalType }} Address Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon :key="addKey" :loading="loading" color="secondary" @click="saveAddress()" :disabled="!item.addressLine1 ||
                    !selectedCountry">
                    <v-icon>save</v-icon>
                </v-btn>
                <v-btn icon color="redPop" @click="closeModal()">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field dense outlined label="Alias" @change="addKey++" v-model="item.alias">
                            </v-text-field>
                            <v-text-field dense outlined label="Address Line 1*" @change="addKey++"
                                v-model="item.addressLine1">
                            </v-text-field>
                            <v-text-field dense outlined label="Address Line 2" @change="addKey++"
                                v-model="item.addressLine2">
                            </v-text-field>
                            <v-text-field dense outlined label="Address Line 3" @change="addKey++"
                                v-model="item.addressLine3">
                            </v-text-field>
                            <v-text-field dense outlined label="Address Line 4" @change="addKey++"
                                v-model="item.addressLine4">
                            </v-text-field>
                            <v-text-field dense outlined label="Postal Code" @change="addKey++"
                                v-model="item.postalCode">
                            </v-text-field>

                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" id="dialog-important-info">

                            <!-- <v-autocomplete  outlined v-model="selectedCountry" :items="countries"
                                placeholder="Start typing to search" @change="addKey++" item-text="name" return-object
                                item-value="name" :loading="loadingCountries" label="Country*">
                            </v-autocomplete> -->





                            <v-autocomplete dense v-model="selectedCountry" :items="countries" item-text="name"
                                item-value="name" return-object label="Country*" outlined>
                                <template v-slot:selection="data">
                                    <v-avatar size="24" class="mr-2" v-if="data.item">
                                        <v-img v-if="data.item.countryCode" contain
                                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`">
                                        </v-img>
                                    </v-avatar>
                                    <span v-if="data.item" style="font-size: 13px">
                                        {{ data.item.name }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <v-avatar size="24" class="mr-2">
                                        <v-img contain v-if="data.item.countryCode"
                                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`">
                                        </v-img>
                                    </v-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>

                            <div v-if="manualSubdivisionsOverride || !subdivisions || subdivisions.length == 0">
                                <v-text-field dense :label="'Province / State'" outlined color="secondary"
                                    v-model="item.subdivision" :disabled="!item.countryCode"
                                    @click:append="manualSubdivisionsOverride = !manualSubdivisionsOverride">
                                </v-text-field>
                            </div>
                            <div v-else>
                                <v-autocomplete dense v-model="item.subdivision" :items="subdivisions" item-text="name"
                                    item-value="name" :loading="loadingSubdivisions" @change="addKey++"
                                    :disabled="!item.countryCode" placeholder="Start typing to search" outlined
                                    hint="hello"
                                    :label="subdivisionDescription == '' || null ? 'Province / State' : subdivisionDescription"
                                    @click:append="manualSubdivisionsOverride = !manualSubdivisionsOverride">
                                </v-autocomplete>
                            </div>

                            <div v-if="manualTownOverride || !towns || towns.length == 0">
                                <v-text-field dense label="Town / Nearest Town" placeholder="Enter town name"
                                    v-model="item.town" outlined color="secondary" :disabled="!item.countryCode"
                                    @click:append="manualTownOverride = !manualTownOverride">
                                </v-text-field>
                            </div>
                            <div v-else>
                                <v-autocomplete dense v-model="item.town" :loading="loadingTowns" :items="towns"
                                    @change="addKey++" :v-model="townParams.search" outlined
                                    placeholder="Start typing to search" item-text="name" item-value="name"
                                    label="Town / Nearest Town" tooltip:append="Switch to manual input"
                                    :disabled="!item.countryCode"
                                    @click:append="manualTownOverride = !manualTownOverride">
                                </v-autocomplete>
                            </div>
                              <div v-if="selectedCountry.iso2 == 'ZA'">
                                <v-autocomplete label="PPECB Office Location" v-model="item.activityPointCode" outlined
                                    dense clearable :loading="loadingActivityPoints" :items="activityPoints"
                                    item-text="name" item-value="code"></v-autocomplete>
                            </div>
                            <div
                                style="display: flex;flex-direction: row;align-items: center;justify-content: space-between">
                                Process Documentations?
                                <v-switch color="green" v-model="item.isDocsAddress">
                                </v-switch>

                            </div>

                            <div
                                style="display: flex;flex-direction: row;align-items: center;justify-content: space-between">
                                Deliveries?
                                <v-switch color="green" v-model="item.isDeliveryAddress">
                                </v-switch>
                            </div>

                            <div
                                style="display: flex;flex-direction: row;align-items: center;justify-content: space-between">
                                HQ?
                                <v-switch color="green" v-model="item.isHQ"></v-switch>
                            </div>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-card>
    </div>
</template>

<script>
export default {
    props: ['item', 'dialogVisible'],
    data: () => ({
        activityPoints: [],
        addKey: 0,
        loading: false,
        loadingCountries: false,
        loadingSubdivisions: false,
        loadingTowns: false,
        countries: [],
        subdivisions: [],
        towns: [],
        selectedCountry: {},
        loadingActivityPoints: false,
        manualTownOverride: false,
        isSubdivisionsEmpty: false,
        manualSubdivisionsOverride: false,
        searchTimer: undefined,
        townParams: {
            limit: 15,
            offset: 0,
            search: ''
        },
        subdivisionDescription: 'Province / State',
    }),
    watch: {
        item(val) {
            // if(val)
            this.setSelectedCountry()
        },
        'dialogVisible': {
            async handler(val) {
                if (val) {
                    this.setSelectedCountry()
                    // if (this.item.modalType == 'Edit') {
                    //     await this.loadItem()
                    // }
                }
            }
        },
        'selectedCountry': {
            async handler(val) {
                if (val && val.iso2) {
                    console.log(val)
                    this.item.country = val.name;
                    this.item.countryCode = val.iso2;
                    this.setSubdivisionDescription();
                    this.loadSubdivisions();
                    this.loadTowns();
                }
            }
        },
        'townParams.search': {
            async handler(val) {
                val = JSON.parse(JSON.stringify(val));
                if (val) {
                    this.loadTowns();
                }
            }
        }
    },
    created() {
        this.loadCountries()
        this.loadActivityPoints()
    },
    async mounted() {
        this.setSelectedCountry()
    },
    methods: {
        async loadActivityPoints() {
            this.loadingActivityPoints = true
            this.activityPoints = await this.$API.getOfficeActivityPoints()
            this.loadingActivityPoints = false
        },
        async loadItem() {
            this.loading = true;
            await this.setSubdivisionDescription();
            await this.loadSubdivisions();
            await this.loadTowns();
            if (this.item.town) { if (!this.towns.some(e => e.name === this.item.town)) { this.manualTownOverride = true } }
            if (this.item.subdivision) {
                if (!this.subdivisions.some(e => e.name === this.item.subdivision)) { this.manualSubdivisionsOverride = true }
            }
            this.loading = false;
        },
        async setSelectedCountry() {
            if (this.item.countryCode) {
                this.selectedCountry = {
                    name: this.item.country,
                    iso2: this.item.countryCode
                }
            }
            // console.log(this.selectedCountry)
            this.loadItem()
            // await this.setSubdivisionDescription();
            // await this.loadSubdivisions();
            // await this.loadTowns();
        },
        async loadCountries() {
            this.loadingCountries = true;
            this.countries = await this.$API.searchCountries();
            this.countries.data.forEach(x => x.countryCode = x.iso2)
            this.countries = this.countries.data
            this.loadingCountries = false;
        },
        async loadSubdivisions() {
            if (this.item.countryCode) {
                this.loadingSubdivisions = true;
                this.subdivisions = await this.$API.getCountrySubdivisionsAdmin(this.item.countryCode)
                this.loadingSubdivisions = false;
            }

        },
        async loadTowns() {
            if (this.item.countryCode) {
                if (this.searchTimer) { clearTimeout(this.searchTimer) }
                this.searchTimer = setTimeout(async () => {
                    this.loadingTowns = true;
                    this.towns = await this.$API.getCountryTowns(this.item.countryCode, { params: this.townParams });
                    this.loadingTowns = false;
                }, 200)
            }
        },
        async setSubdivisionDescription() {
            if (this.item.countryCode) {
                let res = await this.$API.getCountrySubdivisionDescription(this.item.countryCode)
                if (res) { this.subdivisionDescription = res.type[0].toUpperCase() + res.type.substring(1) }
                else { this.subdivisionDescription = 'Province / State' }
            }

        },
        clearVariables() {
            this.manualTownOverride = false;
            this.manualSubdivisionsOverride = false;
            this.selectedCountry = {};
        },
        closeModal() {
            this.loading = true;
            this.$emit('close');
            this.clearVariables();
            this.loading = false;
        },
        async saveAddress() {
            this.loading = true;
            this.addKey++
            if (this.item.modalType == "Add") {
                await this.$API.createOrganisationAddress(this.item);

            } else if (this.item.modalType == "Edit") {
                await this.$API.updateOrganisationAddress(this.item);
            }
            this.$emit('save', this.item);
            this.$emit('close');
            this.$emit('refresh');
            this.loading = false;
        },
    }
}
</script>